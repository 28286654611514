












import Vue from 'vue'
import { ApiResponse, Invoice, Sale } from '@/interfaces'
import { mapState } from 'vuex'
import UnsoldBillInfo from '@/components/Sales/UnsoldBillInfo.vue'

export default Vue.extend({
  name: 'UnsoldBills',
  components: { UnsoldBillInfo },
  props: {
    sale: {
      type: Object as () => Sale,
      required: false,
      default: null,
    },
    activeTab: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      loading: false,
      unsoldBills: [] as Invoice[],
      viewportWidth: 0,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    sale(newVal) {
      if (Number.isInteger(newVal.id) && this.activeTab === 'unsoldBills') {
        this.loadUnsoldBills()
      }
    },
    activeTab(newVal) {
      if (newVal === 'unsoldBills' && this.sale.id) {
        this.loadUnsoldBills()
      }
    },
  },
  mounted() {
    if (this.sale && Number.isInteger(this.sale?.id) && this.activeTab === 'sellers') {
      this.loadUnsoldBills()
    }
    this.viewportWidth = window.innerWidth

    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.viewportWidth = window.innerWidth
    },
    loadUnsoldBills(force = false) {
      if (this.unsoldBills.length === 0 || force) {
        this.busy = true
        const id = this.sale?.id

        const loading = this.$loading({
          target: '#billList',
          text: 'Chargement des données...',
        })

        this.$api
          .get(`/sale/${id}/unsold-bills`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.unsoldBills = apiResponse.data
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
            })
          })
          .then(() => {
            loading.close()
            this.busy = false
          })
      }
    },
  },
})
