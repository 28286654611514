



















































import Vue from 'vue'
import { ApiResponse, Invoice } from '@/interfaces'
import { mapState } from 'vuex'
import InvoicesTable from '@/components/InvoicesTable.vue'

interface UnsoldBill {
  billId?: number
  billNumber?: string
  billSellerName?: string
  relatedReceipts?: Invoice[]
}

export default Vue.extend({
  name: 'UnsoldBillInfo',
  components: { InvoicesTable },
  props: {
    bill: {
      type: Object as () => UnsoldBill,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      loading: false,
      receipts: [] as Invoice[] | undefined,
      deleteAllBillsTrigger: false,
      billId: undefined as number | undefined,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    bill(newVal) {
      if (newVal) {
        this.receipts = newVal.relatedReceipts
        this.billId = newVal.billId
      }
    },
    activeTab(newVal) {
      if (newVal && newVal === 'unsoldBills' && this.bill) {
        this.receipts = this.bill?.relatedReceipts
        this.billId = this.bill?.billId
      }
    },
  },
  mounted() {
    if (this.bill) {
      this.receipts = this.bill?.relatedReceipts
      this.billId = this.bill?.billId
    }
  },
  methods: {
    getRelatedReceipts() {
      if (!this.busy) {
        this.busy = true

        const loading = this.$loading({
          target: '#invoicesTable',
          text: 'Chargement des données...',
        })

        this.$api
          .get(`/invoice/${this.billId}/related-receipts`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.receipts = apiResponse.data
          })
          .finally(() => {
            loading.close()
            this.busy = false
          })
      }
    },
    displayProgress() {
      return `${this.getProgress(true)} / ${this.receipts?.length}`
    },
    getProgress(hidePercent = false): number {
      let soldReceipts = 0
      if (this.bill.relatedReceipts) {
        soldReceipts = this.bill.relatedReceipts.filter((invoice: Invoice) => {
          return invoice?.paymentState === 'paid'
        }).length
        if (soldReceipts === 0) {
          return 0
        }
        if (hidePercent) {
          return soldReceipts
        }
        const percent = (soldReceipts / this.bill.relatedReceipts?.length) * 100
        if (percent > 100) {
          return 100
        }
        return percent
      }
      return 0
    },
    deleteAllBills() {
      this.deleteAllBillsTrigger = true
    },
    resetDeleteTrigger() {
      this.deleteAllBillsTrigger = false
    },
    editInvoice(invoiceId: number) {
      this.$router.push({
        name: 'invoice_edit',
        params: {
          id: invoiceId.toString() ?? '',
        },
      })
    },
  },
})
