

















































































import Vue from 'vue'
import { ApiResponse, Invoice } from '@/interfaces'
import { ElTable } from 'element-ui/types/table'
import { paymentStatesList } from '@/formHelpers'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'InvoicesTable',
  props: {
    invoices: {
      type: Array,
      required: false,
      default: null,
    },
    page: {
      type: String,
      required: false,
      default: null,
    },
    deleteAllReceiptsTrigger: {
      type: Boolean,
      required: false,
      default: false,
    },
    deleteAllBillsTrigger: {
      type: Boolean,
      required: false,
      default: false,
    },
    parentId: {
      type: String,
      required: false,
      default: null,
    },
    activeTab: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      types: [
        { text: 'Inventaire', value: 'inventaire' },
        { text: 'Bordereau acheteur', value: 'bordereau' },
        { text: 'Décompte vendeur', value: 'decompte' },
        { text: 'Standard', value: 'standard' },
      ] as Record<string, string | null>[],
      states: [
        { text: 'Proforma', value: 'proforma', tag: 'warning' },
        { text: 'Emise', value: 'emise', tag: '' },
        { text: 'Comptabilisée', value: 'comptabilisee', tag: 'success' },
      ] as Record<string, string | null>[],
      paymentStatesList,
    }
  },
  computed: {
    ...mapState(['user']),
    comptaRestriction(): boolean {
      return this.user?.roles.includes('RESTRICTION_COMPTA')
    },
  },
  watch: {
    deleteAllReceiptsTrigger() {
      this.deleteAllInvoices('bordereau')
    },
    deleteAllBillsTrigger() {
      this.deleteAllInvoices('decompte')
    },
    activeTab(newVal) {
      if (newVal === 'invoices' && this.parentId && this.invoices.length === 0) {
        this.getInvoices()
      }
    },
  },
  methods: {
    sortChange() {
      this.$emit('sortChange')
    },
    sortContactName(a: Invoice, b: Invoice) {
      if (!a.contactName) return -1
      if (!b.contactName) return 1
      return a.contactName?.toUpperCase().localeCompare(b.contactName.toUpperCase())
    },
    currencyFormatter(row: Invoice) {
      return this.formatCurrency(Number(row.priceIncludingTaxes))
    },
    totalSort(itemA: Invoice, itemB: Invoice) {
      const totalA = parseFloat(String(itemA.priceIncludingTaxes))
      const totalB = parseFloat(String(itemB.priceIncludingTaxes))
      if (totalA == totalB) {
        return 0
      }

      return totalA < totalB ? -1 : 1
    },
    getId(invoice: Invoice) {
      return invoice.id?.toString() ?? ''
    },
    formatCurrency(number: number | null): string {
      if (!number) {
        number = 0
      }
      return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(number)
    },
    formatDate(str: string, hours = false): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        if (hours) {
          const day = new Intl.DateTimeFormat('fr-FR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          }).format(date)
          const hour = new Intl.DateTimeFormat('fr-FR', {
            hour: 'numeric',
            minute: 'numeric',
          }).format(date)

          return `${day} à ${hour}`
        } else {
          return new Intl.DateTimeFormat('fr-FR').format(date)
        }
      } else {
        return '-'
      }
    },
    getStructureName(invoice: Invoice) {
      return invoice.structure ? invoice.structure?.name : '-'
    },
    getInvoiceContactInfo(invoice: Invoice): string {
      let invoiceContactData = ''
      if (invoice.contactName) {
        invoiceContactData = invoice.contactName
      }
      if (invoice.object) {
        invoiceContactData = `${invoiceContactData} - ${invoice.object}`
      }
      return invoiceContactData
    },
    // getInvoiceProperty(invoice: Invoice): string {
    //   return invoice.parentInvoiceId !== null ? 'Avoir' : 'Facture'
    // },
    getInvoiceType(value: string): string {
      return this.types.find((type) => type.value === value)?.text ?? '-'
    },
    getInvoiceState(value: string): Record<string, string | null> | null {
      return this.states.find((state) => state.value === value) ?? null
    },
    getInvoiceStateLabel(state: string): string | null {
      const invoiceState = this.getInvoiceState(state)
      if (invoiceState) {
        return invoiceState.text
      }
      return ''
    },
    getInvoicePaymentState(value: string): Record<string, string | null> | null {
      return (
        this.paymentStatesList.find((paymentState) => paymentState.value === value) ?? {
          text: 'Non soldé',
          value: 'unpaid',
          tag: 'danger',
        }
      )
    },
    getInvoicePaymentStateLabel(paymentState: string): string | null {
      const invoicePaymentState = this.getInvoicePaymentState(paymentState)
      if (invoicePaymentState) {
        return invoicePaymentState.text
      }
      return ''
    },
    getInvoiceTag(state: string, column: string): string | null {
      let stateObject
      if (column === 'state') {
        stateObject = this.getInvoiceState(state)
      } else if (column === 'payment_state') {
        stateObject = this.getInvoicePaymentState(state)
      }
      if (stateObject) {
        return stateObject.tag
      }
      return 'info'
    },
    // goToEditInvoice(row: Invoice, column: Record<string, string>, cell: HTMLTableCellElement): void {
    //   if (!cell.classList.contains('actions') && this.page !== 'accountingExportList') {
    //     this.$router.push({
    //       name: 'invoice_edit',
    //       params: {
    //         id: row.id?.toString() ?? '',
    //       },
    //     })
    //   }
    // },
    editInvoice(invoice: Invoice) {
      if (this.user?.roles.includes('RESTRICTION_COMPTA')) {
        this.downloadInvoice(invoice)
      }
      else {
        if (invoice.id) {
          this.$router.push({
            name: 'invoice_edit',
            params: {
              id: invoice.id?.toString() ?? '',
            },
          })
        }
      }
    },
    downloadInvoice(invoice: Invoice) {
      if (invoice.id) {
        this.$api
          .post(`/invoice/${invoice.id}/generate/no-send`)
          .then(() => {
            const documentUrl = `${process.env.VUE_APP_API_URL}/document/invoice/${invoice.id}/download?contentd=inline`

            window.open(documentUrl, '_blank')
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
      }
    },
    deleteInvoice(invoice: Invoice) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer cette facture ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .delete(`/invoice/${invoice.id}`)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })
            this.$emit('getInvoices')
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
      })
    },
    handleSelectionChange(selectedInvoices: Invoice[]) {
      if (this.page === 'accountingExportList' || this.page === 'invoicesList') {
        this.$emit('updateSelectedInvoices', selectedInvoices)
      }
    },
    clearSelected() {
      ; (this.$refs.invoiceTable as ElTable).clearSelection()
    },
    deleteAllInvoices(invoiceType: string) {
      if (this.deleteAllReceiptsTrigger || this.deleteAllBillsTrigger) {
        this.$confirm(
          'Êtes-vous sûr(e) de vouloir supprimer ce(s) document(s) ? Attention, cette opération est irréversible.',
          'Confirmation',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }
        ).then(() => {
          let deletedInvoices = [] as Invoice[]
          const invoicesToDelete = (this.invoices as Invoice[]).filter((invoice) => {
            if (invoice.type === invoiceType && invoice.state !== 'emise' && invoice.state !== 'comptabilisee') {
              return true
            }
          })

          invoicesToDelete.forEach((invoice: Invoice) => {
            if (invoice.type === invoiceType && invoice.state !== 'emise' && invoice.state !== 'comptabilisee') {
              this.$api
                .delete(`/invoice/${invoice.id}`)
                .then(() => {
                  deletedInvoices.push(invoice)
                  this.$emit('getInvoices')
                })
                .catch((error) => {
                  if (error.response) {
                    const apiResponse = error.response.data as ApiResponse

                    this.$notify({
                      type: 'error',
                      title: 'Erreur',
                      message:
                        apiResponse.message ??
                        'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
                    })
                  }
                })
                .finally(() => {
                  if (deletedInvoices.length == invoicesToDelete.length) {
                    this.$notify({
                      type: 'success',
                      title: 'Succès',
                      message: 'Opération réalisée avec succès !',
                    })
                    this.$emit('resetDeleteTrigger')
                  }
                })
            }
          })
        })
      }
    },
    detachRequisitionItems(invoice: Invoice) {
      let invoiceType = ''
      if (invoice.type === 'bordereau') {
        invoiceType = 'bordereau'
      } else if (invoice.type === 'decompte') {
        invoiceType = 'décompte'
      }
      this.$confirm(
        `Êtes-vous sûr(e) de vouloir détacher tous les lots de ce ${invoiceType} ? Attention, cette opération est irréversible.`,
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .put(`/invoice/${invoice.id}/detach-items`)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })
            this.$emit('getInvoices')
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
      })
    },
    getInvoices() {
      if (this.parentId) {
        this.$api.get(`/invoices/contact/${this.parentId}/list`).then((response) => {
          const apiResponse = response.data as ApiResponse

          this.$emit('updateInvoices', apiResponse.data)
        })
      }
    },
    getStructureColor(invoice: Invoice) {
      return invoice?.structure?.color
    },
  },
})
