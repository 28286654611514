

















import { ApiResponse, Model, Sale } from '@/interfaces'
import Vue from 'vue'

interface Parameter {
  chosenModel: number
  items?: null | number[]
}

export default Vue.extend({
  props: {
    sale: {
      type: Object as () => Sale,
      required: false,
      default: {},
    },
    items: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      chosenModel: null as number | null,
      models: [] as Model[],
      docType: '' as string | null,
      saleDocumentItems: [] as number[],
    }
  },
  watch: {
    sale(newVal) {
      if (Number.isInteger(newVal.id)) {
        this.getTemplates()
      }
    },
    items: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.saleDocumentItems = newVal
      },
    },
  },
  mounted() {
    if (this.sale.id) {
      this.getTemplates()
    }
  },
  methods: {
    generateSaleDocument() {
      this.busy = true

      const loading = this.$loading({
        target: '#container',
        text: 'Chargement des données...',
      })

      if (this.docType === null) {
        this.$notify({
          type: 'error',
          title: 'Erreur',
          message: 'Type de template manquant.',
        })
        return
      }

      let params = {
        chosenModel: this.chosenModel,
      } as Parameter

      if (this.saleDocumentItems) {
        params = {
          ...params,
          items: this.saleDocumentItems,
        }
      }

      this.$api
        .post(`/document/${this.sale.id}/sale/${this.docType}`, params)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.$emit('refreshDocuments', apiResponse.data.documents)

          this.$notify({
            type: 'success',
            title: 'Succès',
            message: apiResponse.message ?? 'Opération réalisée avec succès !',
          })
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
          this.docType = null
          this.chosenModel = null
          loading.close()
          this.$emit('closeModal')
          this.$emit('resetItemEditList')
        })
    },
    getTemplates() {
      if (this.models.length === 0) {
        this.$api.get(`/model/sale/list`).then((response) => {
          const apiResponse = response.data as ApiResponse

          this.models = apiResponse.data
        })
      }
    },
    updateDocType() {
      this.docType = this.models?.find((model: Model) => model.id === this.chosenModel)?.type ?? null
    },
  },
})
